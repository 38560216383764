<template>
  <div class="about">
    <Nav />

    <div class="py-24">
      <section class="text-gray-600 body-font">
        <div class="container px-5 mx-auto">
          <h1 class="text-3xl font-bold title-font text-gray-900 mb-12 text-center">CryptoDragons</h1>
          <div class="flex flex-wrap -m-4">
            <div class="p-4 md:w-1/2 w-full">
              <div class="h-full bg-gray-100 p-8 rounded">
                <h1 class="sm:text-3xl text-2xl font-semibold title-font mb-4 text-gray-900">About</h1>
                <p class="leading-relaxed mb-6">
                  Dragons TON is a first NFT collectable and marketplace in Free TON blockchain inspired by Crypto Punks.<br> 
                  We have started its development before the hype around Ethereum NFTs. Initially, this is a university educational project. But we decided to bring it available to the public.<br> 
                  This token is developed from scratch. It does not follow the ERC721 and TIP-3 standards, but it has a lot in common. We made this website using Vue.js and contracts using Solidity.<br> 
                  To interact with Dragons Root smart contract you need a special wallet wrapper (Dragons wallet). This is necessary for paying fees. Unlike TIP-3, the wallet contract is deployed by the user. So, anybody can make their own wallet and wrapper.<br>
                  Dragons Root address:
                </p>
              </div>
            </div>
            <div class="p-4 md:w-1/2 w-full">
              <div class="h-full bg-gray-100 p-8 rounded">
                <h1 class="sm:text-3xl text-2xl font-semibold title-font mb-4 text-gray-900">FAQs</h1>
                <p class="leading-relaxed mb-6">
                  <b>• Where are these images from?</b><br>
                  We have used a compilation of images generated by different neural networks, based on Style GAN-2 by Gwern's TWDNE and thisanimedoesnotexist<br>
                  <b>• Where i can get TON 💎?</b><br>
                  You can buy TON in telegram bot @chatex or at any exchange (you can find exchanges at Coingecko.com)
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
    
    <div class="pb-24">
      <section class="text-gray-600 body-font">
        <div class="container px-5 mx-auto">
          <div class="flex flex-col text-center w-full mb-12">
            <h1 class="sm:text-3xl text-2xl font-bold title-font mb-4 text-gray-900">Crypto Alliance</h1>
            <p class="lg:w-2/3 mx-auto leading-relaxed text-base">Пацаны совсем ребята</p>
          </div>
          <div class="flex flex-wrap -m-2">
            <div class="p-2 lg:w-1/3 md:w-1/2 w-full">
              <div class="h-full flex items-center border-gray-200 border p-4 rounded-lg">
                <img alt="team" class="w-16 h-16 bg-gray-100 object-cover object-center flex-shrink-0 rounded-full mr-4" src="https://dummyimage.com/80x80">
                <div class="flex-grow">
                  <h2 class="text-gray-900 title-font font-medium">Alexander Bakukhin​​</h2>
                  <p class="text-gray-500">Founder</p>
                </div>
              </div>
            </div>
            
            <div class="p-2 lg:w-1/3 md:w-1/2 w-full">
              <div class="h-full flex items-center border-gray-200 border p-4 rounded-lg">
                <img alt="team" class="w-16 h-16 bg-gray-100 object-cover object-center flex-shrink-0 rounded-full mr-4" src="https://dummyimage.com/84x84">
                <div class="flex-grow">
                  <h2 class="text-gray-900 title-font font-medium">Andrey Guba​</h2>
                  <p class="text-gray-500">Application developer</p>
                </div>
              </div>
            </div>
            
            <div class="p-2 lg:w-1/3 md:w-1/2 w-full">
              <div class="h-full flex items-center border-gray-200 border p-4 rounded-lg">
                <img alt="team" class="w-16 h-16 bg-gray-100 object-cover object-center flex-shrink-0 rounded-full mr-4" src="https://dummyimage.com/88x88">
                <div class="flex-grow">
                  <h2 class="text-gray-900 title-font font-medium">Nikita Volodkevich</h2>
                  <p class="text-gray-500">CTO</p>
                </div>
              </div>
            </div>
            
            <div class="p-2 lg:w-1/3 md:w-1/2 w-full">
              <div class="h-full flex items-center border-gray-200 border p-4 rounded-lg">
                <img alt="team" class="w-16 h-16 bg-gray-100 object-cover object-center flex-shrink-0 rounded-full mr-4" src="https://dummyimage.com/90x90">
                <div class="flex-grow">
                  <a href="https://clck.ru/MNJjN" class="cursor-default"><h2 class="text-gray-900 title-font font-medium">Bair Otkhonov</h2></a>
                  <p class="text-gray-500">UI developer</p>
                </div>
              </div>
            </div>
            
            <div class="p-2 lg:w-1/3 md:w-1/2 w-full">
              <div class="h-full flex items-center border-gray-200 border p-4 rounded-lg">
                <img alt="team" class="w-16 h-16 bg-gray-100 object-cover object-center flex-shrink-0 rounded-full mr-4" src="https://dummyimage.com/94x94">
                <div class="flex-grow">
                  <h2 class="text-gray-900 title-font font-medium">Ivan Serobyan</h2>
                  <p class="text-gray-500">UI developer</p>
                </div>
              </div>
            </div>
            
            <div class="p-2 lg:w-1/3 md:w-1/2 w-full">
              <div class="h-full flex items-center border-gray-200 border p-4 rounded-lg">
                <img alt="team" class="w-16 h-16 bg-gray-100 object-cover object-center flex-shrink-0 rounded-full mr-4" src="https://dummyimage.com/98x98">
                <div class="flex-grow">
                  <h2 class="text-gray-900 title-font font-medium">Sergei Grom</h2>
                  <p class="text-gray-500">Software Engineer</p>
                </div>
              </div>
            </div>
            
            <div class="p-2 lg:w-1/3 md:w-1/2 w-full">
              <div class="h-full flex items-center border-gray-200 border p-4 rounded-lg">
                <img alt="team" class="w-16 h-16 bg-gray-100 object-cover object-center flex-shrink-0 rounded-full mr-4" src="https://dummyimage.com/100x90">
                <div class="flex-grow">
                  <h2 class="text-gray-900 title-font font-medium">Alexander Doshtanov</h2>
                  <p class="text-gray-500">UX designer</p>
                </div>
              </div>
            </div>
            
            <div class="p-2 lg:w-1/3 md:w-1/2 w-full">
              <div class="h-full flex items-center border-gray-200 border p-4 rounded-lg">
                <img alt="team" class="w-16 h-16 bg-gray-100 object-cover object-center flex-shrink-0 rounded-full mr-4" src="https://dummyimage.com/104x94">
                <div class="flex-grow">
                  <h2 class="text-gray-900 title-font font-medium">Vladislav Filonenko</h2>
                  <p class="text-gray-500">Graphic designer</p>
                </div>
              </div>
            </div>
            
          </div>
        </div>
      </section>
    </div>
    

    <Footer />
  </div>
</template>

<script>
  import Nav from "../components/Nav";
  import Footer from "../components/Footer";

  export default {
    components: {Nav, Footer}
  }
</script>